.WebCarousel {
	&.carousel-main-wrapper {
		position: relative;
		.ramadan22 {
			position: relative;
		}
	}

	.carousel_wrapper {
		max-width: 65rem;
		width: 100%;
	}
	&.dark_mode > .ramadan22 {
		.title,
		.subtitle {
			color: white !important;
		}
	}

	.ramadan-carousel-images {
		height: 8rem;
		width: 17rem;
		&-podcasts {
			height: 8rem;
			@media (max-width: 768px) {
				height: 6rem !important;
			}
		}
		&-liveradio {
			@media (max-width: 769px) {
				height: 10rem;
			}
		}
	}
}

.MobileCarousel {
	&.carousel-main-wrapper {
		height: 100%;
		align-items: inherit;
		justify-content: flex-start;
	}

	&.dark_mode {
		background-color: #1b1c1e !important;
		.carousel-title,
		.carousel-subtitle,
		.title,
		.subtitle {
			color: white !important;
		}
	}

	&.generic-rounded-benefits {
		align-items: inherit !important;
		.benefit_box_wrap {
			align-items: center !important;
			margin: 0 0.5rem;
			img {
				border-radius: 50%;
				padding: 0.5rem;
				width: 4rem;
				height: 4rem;
			}
			.title {
				color: #000;
				font-size: 0.9rem;
				text-align: center;
			}
		}
	}

	.scroll_container {
		display: flex;
		height: 50%;
		max-height: 40em;
		padding-left: 1em;
		padding-right: 1em;
		overflow-y: hidden !important;
	}

	.ramadan-carousel-images {
		width: 16rem !important;
		height: 8rem;
		&-podcasts {
			@media (max-width: 768px) {
				height: 6rem !important;
			}
		}
		&-liveradio {
			@media (max-width: 769px) {
				height: 10rem;
			}
		}
	}

	&.ramadan22 {
		.details_wrapper {
			align-items: flex-start;
			width: 92%;
			.carousel-title {
				margin: 1rem 0 0.5rem;
				padding: 0 0.5rem;
				text-align: left;
				&:lang(ar) {
					text-align: right;
				}
			}
			.carousel-subtitle {
				text-align: left;
				padding: 0 0.5rem 1.2rem;
				&:lang(ar) {
					text-align: right;
				}
				@media (max-width: 768px) {
					padding-bottom: 0.75rem !important;
				}
			}
		}
	}
}

.carousel-data-wrapper {
	&:lang(ar) {
		direction: rtl !important;
	}
}

html[lang='ar'] .MobileCarousel > .ramadan22 {
	@media (max-width: 768px) {
		.carousel-title,
		.carousel-subtitle {
			text-align: right !important;
		}
	}
}

.cta-btn {
	margin: 1.5em auto;
	padding: 0.7em 2em;
	font-size: 1.3em;
	max-width: 90%;
	z-index: 1;
	background: #efefef;
	white-space: normal;
	color: black !important;
	img {
		max-width: 1.5em;
	}
}

.cta-padding {
	padding: 0 0.5em;
}

img.carousel-img {
	max-width: 22rem;
	height: 100%;
	border-radius: 0.5rem;
	margin: 0 0.25em 0.5em;
	@media (min-width: 769px) {
		max-height: 10rem;
		margin: 0 0.5em 1em;
	}
}

.carousel {
	&-title {
		margin: 1em auto;
		font-size: 2.4em;
		font-weight: 600;
		color: white;
		padding: 0 0.5em;
	}
	&-subtitle {
		font-weight: 500;
		padding-bottom: 2rem !important;
		@media (min-width: 769px) {
			padding: 0 1rem;
		}
	}
	&-title,
	&-subtitle {
		text-align: center;
	}
}

.titrat-img {
	max-width: 10rem !important;
	max-height: 10rem;
}

.title {
	font-size: 1.1rem;
	font-weight: 600;
	padding: 0 0.75rem;
	@media (max-width: 768px) {
		padding: 0 0.5rem;
	}
}

.subtitle {
	font-size: 0.9rem;
	padding: 0.5rem 0.75rem;
	max-width: 23rem;
	@media (max-width: 768px) {
		padding: 0.5rem;
	}
}

.ramadan22 .title,
.subtitle {
	&:lang(ar) {
		text-align: right;
	}
}

.ramadan22 .subtitle {
	@media (max-width: 768px) {
		padding: 0 0.5rem !important;
		max-width: 15rem;
	}
}

.title,
.subtitle {
	@media (min-width: 769px) {
		color: black;
	}
}

.WebCarousel.dark_mode {
	.carousel-title,
	.carousel-subtitle {
		color: white !important;
	}
}
