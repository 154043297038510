.landing-main-wrapper {
	min-height: 100vh;
	height: 1px;
	background-color: #ffae83;
	padding: 2em 0;
	background-image: url('https://cdnweb.anghami.com/web/assets/img/landing/main/black-wave.png');
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: bottom;
}

.ang-logo-header {
	max-width: 14em;
	margin: 2em 2.5em;
	margin-top: 0;
}

.wave-wrapper {
	background-image: url('https://cdnweb.anghami.com/web/assets/img/landing/main/black-wave.png');
}
